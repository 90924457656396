import { ComparisonId, CourseContentActivityType } from '~/enums/course.enums';

export default defineNuxtRouteMiddleware(async to => {
  const { course, getActivity, setCourseId, isKickoffCompleted, getActivityUrl } = useCourseState();
  const { dispatchMessage } = useSystemMessages();

  if (!course.value && to.params.courseId) {
    await setCourseId(to.params.courseId as string);
  }

  if (!course.value) {
    return;
  }

  // exit if course does not contain kickoff & mastery quizzes
  if (!course.value.kickoffAndMasteryQuizzesEnabled) {
    return;
  }

  const activityId = (() => {
    if (to.params.quizId) {
      return to.params.quizId as string;
    } else if (to.params.lessonId) {
      return to.params.lessonId as string;
    } else if (to.name === CourseContentActivityType.Comparison) {
      return ComparisonId.toString();
    } else {
      return null;
    }
  })();

  if (!activityId) {
    return;
  }

  const targetActivity = getActivity(activityId);

  if (!targetActivity) {
    return;
  }

  if (targetActivity.type === CourseContentActivityType.KickoffQuiz) {
    return;
  }

  // if kickoff is not passed
  // any activity -> kickoff quiz
  if (!isKickoffCompleted.value) {
    const activity = course.value.chapters.kickoff
      .flatMap(chapter => chapter.contents)
      .find(activity => {
        return activity.type === CourseContentActivityType.KickoffQuiz;
      });

    if (!activity) {
      console.error('Couldn\'t find kickoff quiz. This course is broken', course.value.id);
      return;
    }

    return navigateTo(getActivityUrl(activity));
  }

  const getNextRegularQuiz = () => {
    const quizzes = course.value?.chapters?.regular
      ?.flatMap(chapter => chapter.contents)
      ?.filter(activity =>
        activity.type === CourseContentActivityType.Quiz &&
        (activity.progress?.passed === false || !activity.progress),
      );

    if (!quizzes?.length) {
      console.error('Something is wrong, there should be at least one regular quiz left', course.value?.id);
      return;
    }

    return quizzes[0];
  };

  // target: mastery quiz
  if (targetActivity.type === CourseContentActivityType.MasteryQuiz) {
    // if mastery quiz is completed
    // mastery quiz -> comparison page
    if (targetActivity.progress?.finished === true) {
      const activity = getActivity(ComparisonId.toString());

      if (!activity) {
        console.error('Couldn\'t find comparison activity. This course is broken', course.value.id);
        return;
      }

      return navigateTo(getActivityUrl(activity));
    }

    // if mastery quiz is locked
    // mastery quiz -> next regular quiz
    if (targetActivity.locked && isKickoffCompleted.value) {
      dispatchMessage('Not quite there', 'warn', 'Complete all required quizzes/cases to access the Mastery quiz.');

      const nextRegularQuiz = getNextRegularQuiz();
      if (!nextRegularQuiz) {
        return;
      }

      return navigateTo(getActivityUrl(nextRegularQuiz));
    }
  }

  // target: comparison page
  if (targetActivity.type === CourseContentActivityType.Comparison) {
    const masteryQuiz = course.value.chapters.mastery
      .flatMap(chapter => chapter.contents)
      .find(activity => {
        return activity.type === CourseContentActivityType.MasteryQuiz;
      });

    // if mastery quiz is not completed
    // comparison page -> next regular quiz
    if (!masteryQuiz?.progress?.finished) {
      dispatchMessage('Not quite there', 'warn', 'Complete all required and Mastery quizzes/cases to access the Comparison screen.');

      const nextRegularQuiz = getNextRegularQuiz();
      if (!nextRegularQuiz) {
        return;
      }

      return navigateTo(getActivityUrl(nextRegularQuiz));
    }
  }
});